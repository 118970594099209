<template>
  <div class="home">
    <van-form>
      <div class="form" v-if="detail.policy">
        <van-field
          v-model="detail.policy.staffNo"
          name="员工编号"
          label="员工编号"
          placeholder="员工编号"
        />
        <van-field
          v-model="detail.policy.contactsName"
          name="员工姓名"
          label="员工姓名"
          placeholder="员工姓名"
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="detail.policy.contactsBirthday"
          label="出生日期"
          placeholder="点击选择出生日期"
          @click="showCalendar = true"
        />
        <van-calendar
          :default-date="defaultDate"
          v-model="showCalendar"
          :min-date="minDate"
          color="#e24709"
          @confirm="onConfirmBirth"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="detail.policy.contactsSex"
          label="性别"
          placeholder="选择性别"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :default-index="sexIndex"
            :columns="sexColumns"
            @confirm="onConfirmSex"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-model="detail.policy.mail"
          name="邮箱"
          label="邮箱"
          placeholder="填写邮箱"
        />
      </div>
      <div class="form" v-if="detail.policy">
        <van-field
          readonly
          clickable
          name="picker"
          :value="detail.policy.workCity"
          label="现工作城市"
          placeholder="选择现工作城市"
          @click="cityPicker = true"
        />
        <van-popup v-model="cityPicker" position="bottom">
          <van-picker
            show-toolbar
            :default-index="cityIndex"
            :columns="cityColumns"
            @confirm="onConfirmCity"
            @cancel="cityPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          name="picker"
          :value="detail.policy.medicalInsuranceType"
          label="医保类型"
          placeholder="选择现医保类型"
          @click="medicalPicker = true"
        />
        <van-popup v-model="medicalPicker" position="bottom">
          <van-picker
            show-toolbar
            :default-index="medicalIndex"
            :columns="medicalColumns"
            @confirm="onConfirmMedical"
            @cancel="medicalPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          name="picker"
          :value="detail.policy.medicalInsuranceAddress"
          label="医保所在地"
          placeholder="选择医保所在地"
          @click="areaPicker = true"
        />
        <van-popup v-model="areaPicker" position="bottom">
          <van-picker
            show-toolbar
            :default-index="areaIndex"
            :columns="areaColumns"
            @confirm="onConfirmArea"
            @cancel="areaPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          name="picker"
          :value="detail.policy.contactsCardType === 0 ? '身份证' : '护照'"
          label="证件类型"
          placeholder="选择证件类型"
          @click="idPicker = true"
        />
        <van-popup v-model="idPicker" position="bottom">
          <van-picker
            :default-index="idIndex"
            show-toolbar
            :columns="idColumns"
            @confirm="onConfirmId"
            @cancel="idPicker = false"
          />
        </van-popup>
        <van-field
          v-model="detail.policy.contactsCard"
          name="证件号码"
          label="证件号码"
          placeholder="填写证件号码"
        />
        <van-field
          v-model="detail.policy.bankName"
          name="开户银行"
          label="开户银行"
          placeholder="填写开户银行"
        />
        <van-field
          v-model="detail.policy.bankSubName"
          name="开户支行"
          label="开户支行"
          placeholder="填写开户支行"
        />
        <van-field
          v-model="detail.policy.bankCard"
          name="银行账号"
          label="银行账号"
          placeholder="填写银行账号"
        />
      </div>
      <div style="padding: 0 16px 50px">
        <van-button
          round
          block
          color="#E24709"
          type="info"
          @click="submit"
          native-type="submit"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { supportConceptDetail, saveUser } from "../utils/api";
export default {
  data() {
    return {
      defaultDate: undefined,
      showCalendar: false,
      detail: {},
      minDate: new Date(1920, 0, 1),
      sexColumns: ["男", "女", "未知"],
      cityColumns: [
        "纽约",
        "北京",
        "上海",
        "深圳",
        "广州",
        "杭州",
        "南京",
        "苏州",
        "石家庄",
        "大连",
      ],
      medicalColumns: [
        "城镇职工基本医疗保险",
        "城镇居民基本医疗保险",
        "新农合医疗保险",
        "离休干部医疗保险",
      ],
      areaColumns: ["北京", "上海", "深圳", "广州", "杭州"],
      idColumns: ["身份证", "护照"],
      showPicker: false,
      cityPicker: false,
      medicalPicker: false,
      areaPicker: false,
      idPicker: false,
      sexIndex: 0,
      cityIndex: 0,
      medicalIndex: 0,
      areaIndex: 0,
      idIndex: 0,
    };
  },
  created() {},
  methods: {
    //保存
    async submit() {
      if (!this.detail.policy.staffNo) {
        this.$toast("请填写员工编号");
      } else if (!this.detail.policy.contactsName) {
        this.$toast("请填写员工姓名");
      } else if (!this.detail.policy.contactsBirthday) {
        this.$toast("请选择出生日期");
      } else if (!this.detail.policy.contactsSex) {
        this.$toast("请选择性别");
      } else if (!this.detail.policy.mail) {
        this.$toast("请填写邮箱");
      } else if (
        !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(
          this.detail.policy.mail
        )
      ) {
        this.$toast("邮箱格式不正确");
      } else if (!this.detail.policy.workCity) {
        this.$toast("请选择现居住城市");
      } else if (!this.detail.policy.medicalInsuranceType) {
        this.$toast("请选择医保类型");
      } else if (!this.detail.policy.medicalInsuranceAddress) {
        this.$toast("请选择医保所在地");
      } else if (
        this.detail.policy.contactsCardType === "" &&
        this.detail.policy.contactsCardType === undefined &&
        this.detail.policy.contactsCardType === null
      ) {
        this.$toast("请选择证件类型");
      } else if (!this.detail.policy.contactsCard) {
        this.$toast("请填写证件号");
      } else if (!this.detail.policy.bankName) {
        this.$toast("请填写开户银行");
      } else if (!this.detail.policy.bankSubName) {
        this.$toast("请填写开户支行");
      } else if (!this.detail.policy.bankCard) {
        this.$toast("请填写银行账号");
      } else {
        let params = {
          policyId: this.detail.policy.policyId,
          staffNo: this.detail.policy.staffNo,
          contactsName: this.detail.policy.contactsName,
          contactsBirthday: this.detail.policy.contactsBirthday,
          contactsSex: this.detail.policy.contactsSex,
          mail: this.detail.policy.mail,
          workCity: this.detail.policy.workCity,
          medicalInsuranceType: this.detail.policy.medicalInsuranceType,
          medicalInsuranceAddress: this.detail.policy.medicalInsuranceAddress,
          contactsCardType: this.detail.policy.contactsCardType,
          contactsCard: this.detail.policy.contactsCard,
          bankName: this.detail.policy.bankName,
          bankSubName: this.detail.policy.bankSubName,
          bankCard: this.detail.policy.bankCard,
        };
        await saveUser(params).then(() => {
          this.$toast.success("保存成功");
        });
      }
    },
    //保单数据
    async supportConceptDetail() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await supportConceptDetail().then((res) => {
        this.detail = res.data;
        let arr = this.detail.policy.contactsBirthday.split("-");
        let year = arr[0],
          month = arr[1],
          day = arr[2];
        this.defaultDate = new Date(year, month - 1, day);
      });
    },
    //选择出生日期
    onConfirmBirth(date) {
      this.detail.policy.contactsBirthday = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showCalendar = false;
    },
    //选择性别
    onConfirmSex(val) {
      this.detail.policy.contactsSex = val;
      this.showPicker = false;
    },
    //选择城市
    onConfirmCity(val) {
      this.detail.policy.workCity = val;
      this.cityPicker = false;
    },
    //选择医保类型
    onConfirmMedical(val) {
      this.detail.policy.medicalInsuranceType = val;
      this.medicalPicker = false;
    },
    //选择医保所在地
    onConfirmArea(val) {
      this.detail.policy.medicalInsuranceAddress = val;
      this.areaPicker = false;
    },
    //选择证件类型
    onConfirmId(val) {
      let type;
      if (val === "身份证") {
        type = 0;
      } else {
        type = 1;
      }
      console.log(val);
      this.detail.policy.contactsCardType = type;
      this.idPicker = false;
    },
  },
  async mounted() {
    await this.supportConceptDetail();
    //性别默认值
    this.sexColumns.map((item, index) => {
      if (this.detail.policy.contactsSex === item) {
        this.sexIndex = index;
      }
    });
    //现居住城市默认值
    this.cityColumns.map((item, index) => {
      if (this.detail.policy.workCity === item) {
        this.cityIndex = index;
      }
    });
    //医保类型的默认值
    this.medicalColumns.map((item, index) => {
      if (this.detail.policy.medicalInsuranceType === item) {
        this.medicalIndex = index;
      }
    });
    //医保所在地的默认值
    this.areaColumns.map((item, index) => {
      if (this.detail.policy.medicalInsuranceAddress === item) {
        this.areaIndex = index;
      }
    });
    //证件类型的默认值
    let type = this.detail.policy.contactsCardType === 0 ? "身份证" : "护照";
    this.idColumns.map((item, index) => {
      if (type === item) {
        this.idIndex = index;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  /deep/ .van-picker__confirm {
    color: #e24709 !important;
  }
  /deep/ .van-field__label {
    width: 120px !important;
  }
  .form {
    margin-bottom: 20px;
  }
}
</style>